import React from 'react';
import { PropTypes } from 'prop-types';
import Text from '@clearscore/rainbow.text';
import Button from '@clearscore/rainbow.button';
import RainbowContainer from '@clearscore/rainbow.container';
import { useTranslation, Trans } from 'react-i18next';
import useMarketConfig from '@clearscore/hooks.use-market-config';

import style from './login-help.module.css';
import marketConfig from './lib/market-config';
import { PAGE_NAMESPACE } from './lib/constants';

const LoginHelp = ({ routeProps: { newMarketsEnabled } }) => {
    const { t } = useTranslation(PAGE_NAMESPACE);
    const { helpCentres } = useMarketConfig(marketConfig);

    return (
        <RainbowContainer size={RainbowContainer.Size.SMALL}>
            <div className={style.title}>
                <Text.H1>{t('title')}</Text.H1>
            </div>
            <div className={style.sectionWrapper}>
                <div className={style.subtitle}>
                    <Text.H2>{t('forgotPassword.title')}</Text.H2>
                </div>
                <Text.Body1 dataId="forgot-reset-password">
                    <Trans
                        i18nKey="forgotPassword.description"
                        ns={PAGE_NAMESPACE}
                        components={[<Text.Link href="/reset" tag="span" />]}
                    />
                </Text.Body1>
            </div>
            <div className={style.sectionWrapper}>
                <div className={style.subtitle}>
                    <Text.H2>{t('lockedOut.title')}</Text.H2>
                </div>
                <Text.Body1>
                    {t('lockedOut.wrongDetails')} <br />
                    <br />
                    {t('lockedOut.contactUs')}
                </Text.Body1>
            </div>
            <div className={style.helpCentresWrapper}>
                {helpCentres
                    .filter((helpCentre) => newMarketsEnabled || !helpCentre.isNewMarket)
                    .map(({ market, Flag, url }) => (
                        <div className={style.flagWrapper} data-id={`${market}-flag-wrapper`} key={market}>
                            <Flag title={`${market} flag`} width={30} height={20} className={style.flag} />
                            <Text.Link1 isNewTab href={url}>
                                {t(`helpCentres.cta.${market}`)}
                            </Text.Link1>
                        </div>
                    ))}
            </div>
            <Button isResponsive size={Button.sizes.LARGE} href="/login">
                {t('loginAgain')}
            </Button>
        </RainbowContainer>
    );
};

LoginHelp.propTypes = {
    routeProps: PropTypes.shape({
        newMarketsEnabled: PropTypes.bool,
    }).isRequired,
};

export default LoginHelp;
