import connect from '@clearscore/redux.connect';
import withVertical from '@clearscore-group/ui.external-cs-auth.vertical';

import Component from './login-help';

const mapDispatch = (_, { history }) => ({
    handleClose: () => history.push('/'),
    handleLogin: () => history.push('/login'),
});

const ConnectedComponent = connect(null, mapDispatch, { useRouter: true })(Component);

export default withVertical(ConnectedComponent, {
    name: 'login-help',
});
