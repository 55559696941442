import { ZA, AU, CA, GB } from '@clearscore/config.i18n';
import FLAG_GB from '@clearscore/rainbow.icons.uk-united-kingdom';
import FLAG_AU from '@clearscore/rainbow.icons.au-australia';
import FLAG_ZA from '@clearscore/rainbow.icons.za-south-africa';
import FLAG_CA from '@clearscore/rainbow.icons.ca-canada';

export default {
    [GB]: {
        helpCentres: [
            {
                market: AU,
                Flag: FLAG_AU,
                url: 'https://contact.clearscore.com.au/hc/en-us',
                isNewMarket: true,
            },
            {
                market: CA,
                Flag: FLAG_CA,
                url: 'https://help.clearscore.ca/hc/en-us',
            },
            {
                market: ZA,
                Flag: FLAG_ZA,
                url: 'https://contact.clearscore.co.za/hc/en-us',
            },
            {
                market: GB,
                Flag: FLAG_GB,
                url: 'https://contact.clearscore.com/hc/en-us',
            },
        ],
    },
} as const;
